import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import Button from '../../../components/ui/button'
import BoxIcon from '../../../components/box-icon/layout-two'
import { PoliciesWrapper } from './policies-area.style'

const PoliciesTerms = () => {
  
  const result = useStaticQuery(graphql`
    query TermsConditionsQuery {
        allMarkdownRemark(filter: {fields: {slug: {eq: "terms-and-conditions-blog"}}}) {
            edges {
                node {
                    fields {
                      slug
                    }
                    html
                }
            }
        }
    }
  `);

  const [data] = result.allMarkdownRemark.edges;
  
  return (
    <PoliciesWrapper id="policies">
      <Container>
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{ __html: data.node.html }}
            ></div>
          </Col>
        </Row>
      </Container>
    </PoliciesWrapper>
  )
}


PoliciesTerms.defaultProps = {
  policyNane: "",
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px'
      }
    }
  },
  buttonOneStyle: {
    m: '10px',
    hover: '2'
  },
  buttonTwoStyle: {
    m: '10px',
    varient: 'outlined'
  }
}

export default PoliciesTerms;